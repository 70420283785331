<!--
 * @Author: Do not edit
 * @Date: 2021-03-16 09:31:58
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-13 15:55:12
 * @Description: Do not edit
 * @FilePath: \enfry-website\src\views\download.vue
-->
<template>
  <div class="about">
    <div class="banner">
      <img src="@/assets/images/banner-down.png" alt="" class="imgs wow bounceInLeft" />
      <div class="txt-inner  wow bounceInRight">
        <p>电脑手机实时同步</p>
        <p>随时随地移动办公</p>
      </div>
    </div>
    <div class="cent wow fadeInUp">
      <dl class="pc">
        <dt class="col-name">Windows</dt>
        <dd class="col-title">在Windows电脑上运行en+</dd>
        <dd class="col-img1"><img src="@/assets/images/down-img.png" alt="" class="" /></dd>
        <dd class="col-btn"><a href="https://en.enfry.com/" target="_blank">打开应用</a></dd>
      </dl>
      <dl class="iphone">
        <dt class="col-name">iphone和ipad</dt>
        <dd class="col-title">随时随地可在手机和平板上办公</dd>
        <dd class="col-img"><img src="@/assets/images/down-imgphone.png" alt="" class="" /></dd>
        <dd class="col-code"><img src="@/assets/images/down-imgcode.png" alt="" class="" /></dd>
        <dd class="col-btn">App Store 下载</dd>
      </dl>
      <dl class="android">
        <dt class="col-name">Android手机和平板</dt>
        <dd class="col-title">随时随地可在手机和平板上办公</dd>
        <dd class="col-img"><img src="@/assets/images/down-imgphone.png" alt="" class="" /></dd>
        <dd class="col-code"><img src="@/assets/images/down-imgcode.png" alt="" class="" /></dd>
        <dd class="col-btn">Android 下载</dd>
      </dl>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";

export default {
  name: "download",
  mounted() {
    this.$nextTick(() => {
      const wow = new WOW({
        live: false
      });
      wow.init();
    });
  }
};
</script>
<style lang="scss" scoped>
.banner {
  background: url("~@/assets/images/banner-downbg.jpg") no-repeat bottom center;
  height: 577px;
  position: relative;
  .imgs{
    position: absolute;
    left:50%;
    top:150px;
    margin-left:-700px;
  }
  .txt-inner {
    position: absolute;
    left:50%;
    top:240px;
    margin-left:100px;
    p {
      font-size:48px;
      text-align: left;
      margin:0;
      line-height:80px;
    }
  }
}
.cent{
  width:1400px;
  margin:80px auto 130px;
  display:flex;
}
dd{
  margin:0;
}
dl{
  flex: 1;
  height:490px;
  background:#fff;
  margin:0 10px;
  padding:0;
  border:1px solid #e8ecf2;
  box-shadow: 0 0 8px rgba(85, 93, 215, 0.2);
  cursor: pointer;
  border-radius:5px;
  overflow:hidden;
  color:#333;
  padding-top:60px;
  .col-name{
    font-size:30px;
    margin-bottom:10px;
    font-weight: bold;
  }
  .col-title{
    font-size:14px;
    margin-bottom:40px;
  }
  .col-code{
    display: none;
    height:183px;
  }
  .col-img{
    display:block;
    height:183px;
  }
  .col-img1{
    display:block;
    height:183px;
  }
  .col-btn{
    border:1px solid #0183de;
    color:#0183de;
    border-radius:5px;
    width:300px;
    height:50px;
    line-height: 48px;
    font-size:18px;
    margin:30px auto 0;
    a{
      color:#0183de;
      text-decoration: none;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  &:hover{
    box-shadow: 0 0 8px rgba(1, 131, 222, 0.4);
    border:1px solid #0183de;
    .col-img{
      display: none;
    }
    .col-code{
      display: block;
    }
    .col-btn{
      border:1px solid #fff;
      color:#fff;
      background:#0183de;
      a{
        color:#fff;
      }
    }
  }
}
@media (max-width: 750px) {
  .banner {
    height: 260px;
    overflow: hidden;
    background-size: 273%;
    background-position: center top;
    .imgs {
      position: absolute;
      left: 50%;
      top: 50px;
      margin-left: -189px;
      width: 229px;
    }
    .txt-inner {
      left: auto;
      right: 20px;
      top: 70px;
      margin-left: 0;
      h3 {
        font-size: 24px;
        margin: 0;
      }
      p {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -1px;
      }
    }
  }
  dl {
    width:90%;
    height:auto;
    padding-top:30px;
    padding-bottom:40px;
    flex: none;
    margin: 0 auto 20px;
    .col-name{
      font-size: 26px;
    }
    &.pc{
      .col-title{
        margin-bottom: 10px;
      }
    }
    .col-btn{
      line-height:46px;
      height:48px;
      font-size:16px;
      margin-top:20px;
    }
  }
  .inner-title {
    padding-top: 30px;
    font-size: 20px;
    padding-bottom: 20px;
  }
  .txt-cent {
    width: 90%;
    margin: 0 auto;
  }
  .cent{
    width: 100%;
    flex-wrap: wrap; 
    margin:20px 0 100px
  }
  dl{
    .col-btn{
      width:80%;
    }
  }
  .iphone,.android{
    .col-img{
      display: none;
    }
    .col-code{
      display: block;
    }
  }

}
@media (max-width: 330px) {
  .banner {
    height: 194px;
    background-size: 269%;
  }
  .banner .imgs{
    top: 54px;
    margin-left: -162px;
    width: 170px;
  }
}

</style>
